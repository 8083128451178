// Core
import React, { useContext } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import { Col, Row } from 'react-grid-system';
import { InfoButton } from 'components/Buttons';
import RepertoireInfoTable from 'material-design/components/RepertoireInfoTable';
import { Label } from 'components/Labels';
import Lyrics from './Lyrics/Lyrics';

// Icons
import chevronUp from 'images/chevron_up.svg';
import chevronDown from 'images/chevron_down.svg';

// Styles
import styles from '../RepertoireCompositionInfo.module.css';

const MainInfoTable = ({
	repertoireData,
	ownershipTableData,
	requestParameters,
	contributorRoles,
	incompatibilityError,
	unknownError,
	isChevronOpen,
	handleOnClick,
}) => {
	const { showModal } = useContext(UIContext);

	return (
		<div className={styles.RepertoireSongInfo__mainInfoTable}>
			{ownershipTableData.length > 0 && (
				<Row className={styles.row_header}>
					<Col width={'20vw'} className={styles.col_rightHolder}>
						<FormattedHTMLMessage id={'rod.composition.info.rightholder'} />
					</Col>
					<Col
						width={process.env.REACT_APP_CLIENT !== 'mts' ? '20vw' : '25vw'}
						className={styles.col_role}
					>
						<FormattedHTMLMessage id={'rod.composition.info.role'} />
					</Col>
					<Col width={'5vw'} className={styles.col_own}>
						<span>OWN</span>
					</Col>
					<Col width={'5vw'} className={styles.col_col}>
						<span>COL</span>
					</Col>
					{process.env.REACT_APP_CLIENT !== 'mts' && (
						<Col width={'5vw'} className={styles.col_rev}>
							<span>REV</span>
						</Col>
					)}
					<Col className={styles.col_help}>
						<InfoButton
							onClick={showModal({
								text: (
									<>
										{process.env.REACT_APP_CLIENT === 'mts' ? (
											<FormattedHTMLMessage
												id={'rod.repertoire.help.own_col_rev.mts'}
											/>
										) : (
											<FormattedHTMLMessage
												id={'rod.repertoire.help.own_col_rev'}
											/>
										)}
									</>
								),
							})}
						/>
					</Col>
				</Row>
			)}
			{ownershipTableData.length > 0 &&
			Object.keys(requestParameters).length > 0 ? (
				<>
					<RepertoireInfoTable
						repertoireName={'compositions'}
						data={ownershipTableData}
						contributorRoles={contributorRoles}
					/>
				</>
			) : (
				unknownError &&
				!unknownError.conditions &&
				!unknownError.message && (
					<div className={styles.Page}>
						<div className={styles.Form}>
							<div className={styles.Title}>
								<Label font="--gilroy-Medium-32" black>
									<FormattedMessage id={'rod.error_page.unavailable.header'} />
								</Label>
								<div style={{ marginBottom: '10px' }}></div>
								<Label font="--gilroy-Medium-24" black>
									<FormattedMessage id={'rod.error_page.unavailable.body'} />
								</Label>
							</div>
							{unknownError && (
								<>
									<div
										className={styles.detailsWrapper}
										onClick={handleOnClick}
									>
										<Label
											className={styles.detailsHeader}
											font="--gilroy-Medium-22"
											black
										>
											<FormattedMessage
												id={'rod.error_page.unavailable.datails'}
											/>
										</Label>
										<img
											src={!isChevronOpen ? chevronUp : chevronDown}
											alt=""
										/>
									</div>
									{isChevronOpen && (
										<div
											className={styles.detailsText}
											font="--gilroy-Medium-20"
										>
											{window.btoa(unescape(encodeURIComponent(unknownError)))}
										</div>
									)}
								</>
							)}
						</div>
					</div>
				)
			)}
			{repertoireData?.repertoireMetadata?.lyrics && (
				<Lyrics lyrics={repertoireData?.repertoireMetadata?.lyrics} />
			)}
		</div>
	);
};

export default MainInfoTable;
