export const additionalReleaseTypesRu = [
	{
		id: 43,
		code: 'RingtoneRelease',
		is_compound: 0,
		title: 'Рингтон Релиз',
	},
	// TODO временно убираем
	// {
	// 	id: 70,
	// 	code: 'TikTokRelease',
	// 	is_compound: 0,
	// 	title: 'TikTok Релиз',
	// },
];

export const additionalReleaseTypesEn = [
	{
		id: 43,
		code: 'RingtoneRelease',
		is_compound: 0,
		title: 'Ringtone Release',
	},
	// TODO временно убираем
	// {
	// 	id: 70,
	// 	code: 'TikTokRelease',
	// 	is_compound: 0,
	// 	title: 'TikTok Release',
	// },
];
