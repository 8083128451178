import * as React from 'react';
import { useEffect, useState, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import MuiMultipleCheckedSkeleton from '../MuiMultipleCheckedSkeleton/MuiMultipleCheckedSkeleton';
import { Label } from 'components/Labels';

// Styles
import s from './MuiMultipleCheckedTracks.module.css';

const MultipleSelectRecordings = ({
	rootContext: {
		getAccountStatisticAllRecordings,
		getAccountStatisticAuthorAllRecordings,
	},
	dateFrom,
	dateTo,
	accountId,
	isAdmin,
	releaseIds,
	artists,
	recordingsState,
	lang,
	chartMode,
	outlets,
	topTrack,
	setHasRecordingsForRender,
	setResetRecordings,
	setIsGetRecordings,
	handleResetTopTrack,
	setIsResetPerformers,
	setIsResetReleases,
	isResetReleases,
	isResetPerformers,
}) => {
	const [recordings, setRecordings] = useState([]);
	const [checkedRecordingIds, setCheckedRecordingIds] = useState([]);
	const [cachedIds, setCachedIds] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [isShowSkeleton, setIsShowSkeleton] = useState(false);
	const [isEmptyData, setIsEmptyData] = useState(false);
	const [nameRecording, setNameRecording] = useState('');
	const [isFirstRender, setIsFirstRender] = useState(true);

	const [topTrackChoose, setTopTrackChoose] = useState({});
	const [resetActive, setResetActive] = useState(false);

	const [page] = useState(1);
	const [limit] = useState(100);

	const allRecordingsRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorAllRecordings
			: getAccountStatisticAllRecordings;

	const getRecordings = (releaseIds = []) => {
		const topTrackId = topTrack ? topTrack.id : [];
		setIsGetRecordings(false);
		setIsShowSkeleton(true);
		allRecordingsRequest(
			isAdmin,
			accountId,
			searchValue,
			page,
			limit,
			dateFrom,
			dateTo,
			releaseIds,
			resetActive ? [] : topTrackId,
			outlets
		).then((res) => {
			const data = res.data.data.map((item) => ({
				...item,
				checked: checkedRecordingIds.includes(item.heaven11_track_id)
					? true
					: false,
			}));

			setHasRecordingsForRender(data.length ? true : false);
			setIsResetPerformers(false);
			setIsResetReleases(false);

			const allRecordingsButton = {
				heaven11_track_id: 0,
				raw_title: lang === 'en' ? 'Select all' : 'Выбрать все',
				checked: searchValue.length > 0 ? false : true,
			};

			let recordingsData;

			if (data.length) {
				recordingsData = [allRecordingsButton, ...data];
				setIsEmptyData(false);
			} else {
				recordingsData = [];
				setIsEmptyData(true);
			}

			if (releaseIds.length || topTrack?.raw_title) {
				const allRecordings = recordingsData.map(
					(item) => item.heaven11_track_id
				);

				setCheckedRecordingIds(allRecordings);
				recordingsState(allRecordings);
			}

			if (isResetPerformers || isResetReleases) {
				setCheckedRecordingIds([]);
				recordingsState([]);
			}

			setRecordings(recordingsData);
			setIsShowSkeleton(false);
			setIsGetRecordings(true);
		});
	};

	const getAuthorRecordings = (artists = []) => {
		const topTrackId = topTrack ? topTrack.id : [];
		setIsGetRecordings(false);
		setIsShowSkeleton(true);
		allRecordingsRequest(
			isAdmin,
			accountId,
			searchValue,
			page,
			limit,
			dateFrom,
			dateTo,
			artists,
			resetActive ? [] : topTrackId,
			outlets
		).then((res) => {
			const data = res.data.data.map((item) => ({
				...item,
				checked: checkedRecordingIds.includes(item.heaven11_release_id)
					? true
					: false,
			}));
			setHasRecordingsForRender(data.length ? true : false);
			setIsResetPerformers(false);
			setIsResetReleases(false);

			const allRecordingsButton = {
				heaven11_track_id: 0,
				raw_title: lang === 'en' ? 'Select all' : 'Выбрать все',
				checked: searchValue.length > 0 ? false : true,
			};

			let recordingsData;

			if (data.length) {
				recordingsData = [allRecordingsButton, ...data];
				setIsEmptyData(false);
			} else {
				recordingsData = [];
				setIsEmptyData(true);
			}

			if (artists.length || topTrack?.raw_title) {
				const allRecordings = recordingsData.map(
					(item) => item.heaven11_track_id
				);

				setCheckedRecordingIds(allRecordings);
				recordingsState(allRecordings);
			}

			if (isResetPerformers) {
				setCheckedRecordingIds([]);
				recordingsState([]);
			}

			setRecordings(recordingsData);
			setIsShowSkeleton(false);
			setIsGetRecordings(true);
		});
	};

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}
		if (chartMode === 'phono') {
			if (searchValue.length || isResetPerformers || isResetReleases) {
				getRecordings();
			} else {
				getRecordings(releaseIds);
			}
		} else {
			if (searchValue.length || isResetPerformers || isResetReleases) {
				getAuthorRecordings();
			} else {
				getAuthorRecordings(artists);
			}
		}

		setTopTrackChoose(topTrack);
		setResetActive(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateFrom, dateTo, releaseIds, searchValue, artists, topTrack]);

	const handleCheckedRecording = (checkedRecording) => {
		setNameRecording(checkedRecording.raw_title);

		let mappedRecordings;

		if (checkedRecording.heaven11_track_id === 0 && checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => ({
				...release,
				checked: false,
			}));

			setCheckedRecordingIds([]);
		}

		if (checkedRecording.heaven11_track_id === 0 && !checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => ({
				...release,
				checked: true,
			}));

			setCheckedRecordingIds(
				recordings.map((release) => release.heaven11_track_id)
			);
		}

		if (
			!(checkedRecording.heaven11_track_id === 0) &&
			checkedRecording.checked
		) {
			mappedRecordings = recordings.map((release) => {
				if (release.heaven11_track_id === 0) {
					return { ...release, checked: false };
				}

				if (release.heaven11_track_id === checkedRecording.heaven11_track_id) {
					return { ...release, checked: false };
				} else {
					return release;
				}
			});

			setCheckedRecordingIds(
				checkedRecordingIds.filter(
					(id) => id !== checkedRecording.heaven11_track_id && id !== 0
				)
			);
		}

		if (
			!(checkedRecording.heaven11_track_id === 0) &&
			!checkedRecording.checked
		) {
			mappedRecordings = recordings.map((release) => {
				if (release.heaven11_track_id === 0) {
					return { ...release, checked: false };
				}

				if (release.heaven11_track_id === checkedRecording.heaven11_track_id) {
					return { ...release, checked: true };
				} else {
					return release;
				}
			});

			setCheckedRecordingIds([
				...checkedRecordingIds.filter((checkedId) => checkedId !== 0),
				checkedRecording.heaven11_track_id,
			]);
		}

		setRecordings(mappedRecordings);
	};

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
	};

	const debouncedSearchHandler = useCallback(
		debounce(handleSearchInput, 500),
		[]
	);

	const handleClickOnly = (e, recording) => {
		setNameRecording(recording.raw_title);
		e.stopPropagation();

		const mappedRecordings = recordings.map((item) => ({
			...item,
			checked: item.heaven11_track_id === recording.heaven11_track_id,
		}));

		setCheckedRecordingIds([recording.heaven11_track_id]);

		setRecordings(mappedRecordings);
	};

	const handleSelectClose = () => {
		if (cachedIds !== checkedRecordingIds) {
			recordingsState(checkedRecordingIds.filter((id) => id !== 0));
			setResetRecordings(false);
		}
	};

	const handleResetFilter = () => {
		setCheckedRecordingIds([]);
		recordingsState([]);
		setIsEmptyData(false);
		setTopTrackChoose(null);
		setNameRecording('');
		setResetActive(true);
		handleResetTopTrack();
	};

	const handleSelectOpen = () => {
		setCachedIds(checkedRecordingIds);
	};

	useEffect(() => {
		if (!recordings.length && !checkedRecordingIds.length && !isEmptyData) {
			if (chartMode === 'phono') {
				getRecordings(releaseIds);
			} else if (chartMode === 'compo') {
				getAuthorRecordings(artists);
			}
		}

		if (isResetPerformers || isResetReleases) {
			if (chartMode === 'phono') {
				getRecordings();
			} else if (chartMode === 'compo') {
				getAuthorRecordings();
			}
		}
	}, [
		recordings,
		checkedRecordingIds,
		isEmptyData,
		chartMode,
		isResetPerformers,
		isResetReleases,
	]);

	useEffect(() => {
		setTopTrackChoose(null);
	}, [resetActive]);

	return (
		<FormControl variant="standard" className={s.formControl}>
			<Input
				error={!checkedRecordingIds.length && recordings.length !== 0}
				id="tracks-select-label"
			>
				<FormattedMessage id="rod.statistic.tracks" />
			</Input>

			<SelectCustom
				labelId="tracks-select-label"
				id="tracks-select"
				multiple
				error={!checkedRecordingIds.length && recordings.length !== 0}
				value={isShowSkeleton ? [0] : recordings}
				onOpen={handleSelectOpen}
				onClose={handleSelectClose}
				disabled={isShowSkeleton}
				MenuProps={{
					style: { zIndex: 99999999999 },
				}}
				renderValue={() => {
					if (topTrackChoose?.raw_title && !resetActive) {
						return <span>{recordings[1].raw_title}</span>;
					}

					if (
						recordings.length === 1 &&
						recordings[0].heaven11_track_id === 0
					) {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} /> 0{' '}
								<FormattedMessage id={'rod.statistic.selected-tracks'} />
							</>
						);
					}

					if (checkedRecordingIds.length === 1) {
						return <span>{nameRecording}</span>;
					} else if (!isShowSkeleton && recordings.length !== 0) {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} />{' '}
								{checkedRecordingIds.includes(0)
									? checkedRecordingIds.length - 1
									: checkedRecordingIds.length}{' '}
								<FormattedMessage id={'rod.statistic.selected-tracks'} />
							</>
						);
					} else if (isShowSkeleton) {
						return <FormattedMessage id={'rod.statistic.updating-data'} />;
					}
				}}
			>
				<FormattedMessage id="rod.statistic.search-recordings">
					{(label) => (
						<TextFieldInput
							className={s.search}
							label={label}
							initialValue={searchValue ? searchValue : ''}
							defaultValue={searchValue}
							onChange={debouncedSearchHandler}
							variant="standard"
						/>
					)}
				</FormattedMessage>
				<div className={s.wrapper}>
					{!isShowSkeleton ? (
						recordings.map((recording, index) => (
							<MenuItem
								key={recording.heaven11_track_id + index}
								value={recording.raw_title}
								onClick={() => handleCheckedRecording(recording)}
							>
								<CheckboxBlack
									checked={checkedRecordingIds.includes(
										recording.heaven11_track_id
									)}
								/>
								<Label>{recording.raw_title}</Label>
								<button
									className={s.btnOnly}
									onClick={(e) => handleClickOnly(e, recording)}
								>
									<FormattedMessage id={'rod.statistic.only'} />
								</button>
							</MenuItem>
						))
					) : (
						<MuiMultipleCheckedSkeleton />
					)}

					{!recordings.length && !isShowSkeleton && (
						<p className={s.noData}>
							<FormattedMessage id={'rod.no_data_select'} />
						</p>
					)}
				</div>
			</SelectCustom>
			{!checkedRecordingIds.length && recordings.length !== 0 && (
				<p className={s.validateInput}>
					<FormattedMessage id={'rod.release.info.filters.modal.track.error'} />
				</p>
			)}

			<button
				className={s.buttonResetFilter}
				onClick={handleResetFilter}
				style={
					!recordings.length && !isShowSkeleton
						? { opacity: '0.4', pointerEvents: 'none' }
						: {}
				}
			>
				<FormattedMessage id={'rod.statistic.reset-filter'} />
			</button>
		</FormControl>
	);
};

export default compose(withRoot, withAuth)(MultipleSelectRecordings);
