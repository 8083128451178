/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 10/03/2021, 19:16
 */
// eslint-disable-next-line
const errors = {
	/*Field errors*/
	'rod.error.be_confirmed': 'Passwords differ',
	'rod.error.does_not_match': 'Data do not match',
	'rod.error.boolean_type': 'Temporary unavailable',
	'rod.error.cap_letter_required': 'Требуется 1 прописная буква',
	'rod.error.code.invalid':
		'Incorrect code. You have {count, number} {count, plural, one {attempt} few {attempts} other {attempts}} to provide a code',
	'rod.error.code.limit': 'Attempt limit exceeded. Switch to manual signing',
	'rod.error.code.retry': 'Please try later',
	'rod.error.code.forbidden':
		'Sign attempt limit exceeded. Please contact support',
	'rod.error.code_code_exhausted':
		'You have entered an incorrect code 3 times. PLease request code once again and follow the instruction.',
	'rod.error.code_exhausted': 'Retry limit exceeded. Try later', //todo формат лексемы от бэка без code_
	'rod.error.date_format': 'Invalid date format',
	'rod.error.date_past': 'Date should not be in past',
	'rod.error.digit_required': 'Требуется 1 цифра',
	'rod.error.email_exists': 'User with such email is already exists',
	'rod.error.exists_in_signup': 'User with such email is already exists',
	'rod.error.email_format': 'Incorrect format of email address',
	'rod.error.greater_than_or_equal_value':
		'Must be larger or equal to {num, number}',
	'rod.error.invalid_upc_format': 'invalid UPC format',
	'rod.error.invalid_code_invalid':
		'This code is invalid - the bank identifier does not exist',
	'rod.error.incorrect_username_or_password':
		'Incorrect email address or password',
	'rod.error.exists_in_signup.email':
		'There is already a user with this email in the system',
	'rod.error.exists_in_signup.phone':
		'There is already a user with this phone in the system',
	'rod.error.exists_in_user.email':
		'There is already a user with this email in the system',
	'rod.error.exists_in_user.phone':
		'There is already a user with this phone in the system',
	'rod.error.unique_value':
		'This mail is already in use. Please use another one',
	'rod.error.email.check.invalid':
		'this email doesn`t exist or the format is incorrect',
	'rod.error.invalid': 'Incorrect format',
	'rod.error.non_unique': 'Non unique data',
	'rod.error.invalid_password': 'Password is too simple',
	'rod.error.invalid_password_confirmation': 'Password is too simple',
	'rod.error.validation.cyrillic': 'use the Latin alphabet',
	'rod.error.cyrillic_invalid': 'use the Latin alphabet',
	'rod.error.cyrillic_invalid_password': 'use the Latin alphabet',
	'rod.error.integer_type': 'Digits allowed only',
	'rod.error.letter_required': 'One lower-case letter required',
	'rod.error.max_length':
		'Maximum value {num, number} {num, plural, one {symbol} few {symbols} other {symbols}}',
	'rod.error.max_length_no_value': 'Value is too long',
	'rod.error.min_length':
		'Minimum value {num, number} {num, plural, one {symbol} few {symbols} other {symbols}}',
	'rod.error.min_length_no_value': 'Value is too short',
	'rod.error.not_present': 'Mandatory field',
	'rod.error.password_invalid': 'Password does not meet requirements',
	'rod.error.password_required': 'Mandatory field',
	'rod.error.phone_exists': 'This number already in use',
	'rod.error.country': 'Select country from the list',
	'rod.error.required': 'Mandatory field',
	'rod.error.required_without': 'Mandatory field',
	'rod.error.max_date_value': 'Desired date can not be more then 5 days',
	'rod.error.min_date_value': 'Desired date can not be early then to day date',
	'rod.error.check_box_outlets_required': 'Minimum 1 outlet must be checked',

	'rod.error.review.composition': 'error sending for moderation',
	'rod.error.required_roles':
		'Composition should have at least one composer and/or words author',
	'rod.error.required_submitter':
		'You need to control at least one share to add the composition',
	'rod.error.required_society': 'Invalid society',
	'rod.error.required_if': 'Mandatory field',
	'rod.error.required_unless': 'Mandatory field',
	'rod.error.min_repertoire_value': 'Value should be more than 1',
	'rod.error.string_type': 'Mandatory field',
	'rod.error.required_with': 'Mandatory field',
	'rod.error.currency_country_not_match': `Currency in the contract and the bank's country do not match`,
	'rod.error.image_dimensions': 'cover image too small',
	'rod.error.max_size.genres':
		'Please select no more than {value} genres from the list',
	'rod.error.max_size.genres.fixed':
		'The number of selected genres has been exceeded. There should be no more than three',
	'rod.error.max_size': 'File size too large. Should be less than {value} Mb',
	'rod.error.image_wrong_format': 'wrong format',
	'rod.error.image_size_exceeded':
		'image file size cannot be greater than 40 MB',
	'rod.error.image_minimum_size':
		'image size should be at least 1500 x 1500 pixels',
	'rod.error.image_aspect_ratio': 'the image must have an aspect ratio of 1:1',

	'rod.error.wrong_format': 'Wrong format',
	'rod.error.before_or_equal_date': 'Wrong format',
	'rod.error.time_below-1970': 'Date can`t be early then 01.01.1970',
	'rod.error.user_exist': 'This name is already in use',
	'rod.error.user_exists': 'This user name is already in use',
	'rod.error.code_invalid': 'Invalid code',
	'rod.error.min_age': '{num, number} yo is a minimal age',
	'rod.error.min_age_no_value':
		'Service is not available for person under 18 years old',
	'rod.error.wrong_date':
		'Expiration date can not be earlier that date of issue',
	'rod.error.expired': 'Passport has expired',
	'rod.error.contracts.add.conditions':
		'Please select at least one asset type for the contract',
	'rod.error.contracts.add.conditions_with_lyric':
		'To collect royalties for lyrics, you need to select at least one of Composition collection options above',
	'rod.error.maxDate': 'Enter original sale start date (should be in past)',
	'rod.error.minDate': 'Desired date can not be early 7 days then to day date',
	'rod.error.sale.start.date.sla.one.plus':
		'Desired date can not be early one day from now',
	'rod.error.sales_start_date_2_days': `Sales start date must be no earlier than 2 days from today's date`,
	'rod.error.sales_start_date_3_days': `Sales start date must be no earlier than 3 days from today's date`,
	'rod.error.statistic.minDate': 'Date can`t be early than ',
	'rod.error.failed_to_load_file': 'failed to load file',
	'rod.error.failed_to_load_files': 'loading error',
	'rod.error.load.data.error': 'data loading error...',
	'rod.error.validate.cyrillic': 'Only cyrillic characters are required',
	'rod.error.validation.latin': 'Only latin characters are required',
	'rod.error.validate.latin': 'Only latin characters are required',

	'rod.error.select.another.release.type': 'Please select another release type',

	/*Page errors*/
	'rod.error_page.denied.body': 'You have no permission to access that data',
	'rod.error_page.denied.header': 'Access is not permitted',
	'rod.error_page.session_expired.header': 'Session expired',

	'rod.error_page.session_expired.body':
		'Please <a>log in</a> again to continue ',
	'rod.error_page.not_found.body': 'Page is not found',
	'rod.error_page.not_found.header': 'Error',
	'rod.error_page.unavailable.body':
		'Please try to refresh the page a little later.',
	'rod.error_page.unavailable.header': 'an error occurred',
	'rod.error_page.unavailable.datails': 'More details',

	'rod.field.contracts.contract_error.value.valid': 'missing some pages',
	'rod.field.contracts.contract_error.value.signature':
		'missing some signatures',
	'rod.field.contracts.contract_error.value.quality':
		'low quality of the document',
	'rod.field.upload.moderation.title': 'Some error have found',
	'rod.field.upload.moderation.title.quality': 'low quality of the document',
	'rod.field.upload.moderation.title.invalid':
		'invalid document (expired or incorrect)',
	'rod.field.upload.moderation.title.invalid_quality':
		'invalid and low quality document',
	'rod.field.upload.moderation.text': 'Please improve it and <a>try again</a>',
	'rod.error.forgot_password.not_found':
		'Password reset is not available for this user. If you are sure that the data entered is correct, please contact support',
	'rod.error.forgot_password.not_coincide': 'Passwords don`t coincide',
	'rod.error.forgot_password.wrong_data':
		'Cannot reset password. If you are sure that email is correct, please contact support',

	'rod.error.something_went_wrong': 'something went wrong, try again later',

	/*Release create */
	'rod.release_create.error.required': 'Mandatory field',
	'rod.release_create.error.cover_required': 'error: cover is mandatory',
	'rod.release_create.error.track_required':
		'error: Release should have at least 1 track',
	'rod.release_create.error.upload_only_one_track':
		'For release type {type}, only 1 track is allowed',
	'rod.release_create.error.max_upload_count': 'Track count is too large',
	'rod.release_create.error.upload_universal_error':
		'File {name} size too large',
	'rod.release_create.error.upload_max_size_error':
		'File {name} size too large',
	'rod.release_create.error.upload_file_type_error':
		'Incorrect format. Accepted audio formats: FLAC or WAV',
	'rod.release_create_snipped.date_format': 'Invalid value',
	'rod.release_create_snipped.required': 'Mandatory field',
	'rod.release_create_snipped.required_with': 'Please fill both fields',
	'rod.release_create_snipped.after_date':
		'Fragment end should be later than start',
	'rod.release_create.error.lyrics.required': 'Mandatory field',
	'rod.release_create.error.lyrics.max_length': 'Max length is 10000 chapters',
	'rod.release_create.error.title.does not match':
		'The title of the track does not match the title of the release',
	'rod.release_create.error.subtitle.does not match':
		'The name of the version does not match the spelling in the first step',
	'rod.error.does not match': 'name does not match',

	/* Remove drafts*/
	'rod.error.failed': 'Cannot remove this draft',

	/*Contracts*/
	'rod.error.change_sign_type_contract':
		'An error occured. Please contact support',
	//Admin FAQ//
	'rod.error.both_lang.required':
		'You wrote rubric title only in one language. Please, write to both lenguages.',
	'rod.error.upload_file': 'Please attach document',
};

export default errors;
